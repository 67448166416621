var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "mode": "server",
      "name": "account-aliases",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "row-select": _vm.onRowSelect,
      "server-filter": function serverFilter($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "pagination": function pagination($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "sort": function sort($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row['Alias ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }