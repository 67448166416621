<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      mode="server"
      name="account-aliases"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @row-select="onRowSelect"
      @server-filter="getData(appliedFilters, $event)"
      @pagination="getData(appliedFilters, $event)"
      @sort="getData(appliedFilters, $event)"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row['Alias ID'])"
          >
            <font-awesome-icon icon="trash" />
          </span>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

import { MODULES } from "@/shared/constants";

export default {
  name: "AccountAliasesTable",
  props: {
    name: {
      type: String,
      default: ""
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    hideSettingsBar: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 50
    },
    autoload: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Array,
      default: () => {
        return [];
      }
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    TableCustom
  },
  data: function() {
    return {
      MODULES: MODULES,
      rawData: {},
      appliedFilters: [],
      selectedRows: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          columns: [
            "ID",
            "Account Name",
            "Assigned To",
            "Category",
            "Country",
            "State",
            "City",
            "Street",
            "Alias ID",
            "Alias",
            "Alias State",
            "Alias City",
            "Alias Street",
            "User",
            "Created",
            "Actions"
          ],
          filterable: [
            "ID",
            "Account Name",
            "Assigned To",
            "Category",
            "Country",
            "State",
            "City",
            "Street",
            "Alias ID",
            "Alias",
            "Alias State",
            "Alias City",
            "Alias Street",
            "User",
            "Created"
          ],
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          perPage: this.perPage,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectable,
          hideSettingsBar: this.hideSettingsBar
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {
    hasSelectedRows() {
      return this.selectedRows.length > 0;
    },
    has2SelectedRows() {
      return this.selectedRows.length > 1;
    }
  },
  created() {
    if (this.showActions === false) {
      this.dataTable.options.columns = this.dataTable.options.columns.filter(
        c => c !== "Actions"
      );
    }
  },
  mounted() {
    if (this.autoload) this.getData({});
  },
  methods: {
    getSelectedRows() {
      return this.$refs.dataTable.getSelectedRows();
    },
    onRowSelect(e) {
      this.selectedRows = this.$refs.dataTable.getSelectedRows();

      this.$emit("row-select", e);
    },

    async getData(payload, options) {
      let self = this;

      this.dataTable.isLoading = true;

      //this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      payload.serverOptions = options || self.$refs.dataTable.serverOptions;

      //if filter has been passed through props [ex. Duplicate search table] then override column filters
      if (this.filter.length > 0) {
        payload.serverOptions.filter = this.filter;
        payload.serverOptions.strict_filter = "true";
      }

      //required for prevNext functionaltiy
      this.$customTable.saveAppliedFilters(
        this.$constants.MODULES.Accounts,
        this.appliedFilters
      );
      //console.log(payload);
      return this.$api
        .post("accounts/aliases", payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response.data;

          if (response.count === 0) return;

          if (self.$refs.dataTable)
            self.$refs.dataTable.setTotalPaginationRows(response.count);

          //20210131 #316 global search
          //self.$emit("loaded", self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },

    deleteItem: async function(id) {
      console.log("id:", id);

      let account = this.dataTable.dataSet.find(
        item => item["Alias ID"] === id
      );

      let confirm = await this.$form.showConfirmation(
        `Account alias #${account["Alias ID"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      //self.dataTable.dataSet = self.dataTable.dataSet.filter(i => i["ID"] !== account.ID)
      console.log("account[Alias ID]:", account["Alias ID"]);
      this.$api
        .delete(`accounts/aliases/${account["Alias ID"]}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.getData(self.appliedFilters);

          self.$form.makeToastError(response.message);
        });
    }
  }
};
</script>

<style scoped>
::v-deep .modal-body {
  overflow-y: visible !important;
}
</style>
